import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { AuthProvider } from './AuthContext';
import './custom.css';
import { VersionChecker } from './components/VersionChecker';

export default class App extends Component {
    static displayName = App.name;
    state = { showUpdateAlert: false };

    componentDidMount() {
        window.addEventListener('swUpdate', this.handleServiceWorkerUpdate);
        this.checkAppVersion();
    }

    componentWillUnmount() {
        window.removeEventListener('swUpdate', this.handleServiceWorkerUpdate);
    }

    handleServiceWorkerUpdate = () => this.setState({ showUpdateAlert: true });

    refreshApp = () => window.location.reload();

    checkAppVersion = () => {
        const currentVersion = process.env.REACT_APP_VERSION;
        const lastVersion = localStorage.getItem('app_version');
        if (currentVersion && lastVersion && currentVersion !== lastVersion) {
            if ('caches' in window) {
                caches.keys().then(names => names.forEach(name => caches.delete(name)));
            }
            localStorage.setItem('app_version', currentVersion);
            window.location.reload(true);
        } else if (currentVersion) {
            localStorage.setItem('app_version', currentVersion);
        }
    }

    render() {
        return (
            <Layout>
                <VersionChecker />
                {this.state.showUpdateAlert && (
                    <Alert variant="info" className="position-fixed top-0 start-50 translate-middle-x m-3" style={{ zIndex: 9999 }}>
                        <Alert.Heading>New Version Available</Alert.Heading>
                        <p>A new version is available. Please refresh to update.</p>
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-primary" onClick={this.refreshApp}>Update Now</button>
                        </div>
                    </Alert>
                )}
                <AuthProvider>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                </AuthProvider>
            </Layout>
        );
    }
}
/*
import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { AuthProvider } from './AuthContext';

import './custom.css';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <AuthProvider>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                </AuthProvider>
            </Layout>
        );
    }
}
/*
const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Route path="/login" component={LoginPage} />
                <ProtectedRoute path="/home" component={HomePage} />
                <ProtectedRoute path="/admin" component={AdminPage} requiredRole="admin" />
            </Router>
        </AuthProvider>
    );
};

export default App;
*/