import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ children }) => {
    const [isValid, setIsValid] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const checkTokenValidity = async () => {
        const authToken = localStorage.getItem('authToken');

        if (!authToken) {
            setIsValid(false);
            setIsLoading(false);
            return;
        }

        try {
            const decodedToken = jwtDecode(authToken);
            const expirationTime = decodedToken.exp * 1000;
            const currentTime = Date.now();
            const refreshThreshold = 5 * 60 * 1000; // 5 minutes before expiration

            if (expirationTime < currentTime) {
                // Token is expired
                localStorage.removeItem('authToken');
                setIsValid(false);
            } else if (expirationTime < currentTime + refreshThreshold) {
                // Token will expire soon, try to refresh it
                await refreshToken();
            }
        } catch (error) {
            localStorage.removeItem('authToken');
            setIsValid(false);
        }

        setIsLoading(false);
    };

    const refreshToken = async () => {
        try {
            const response = await fetch('/auth/refreshtoken', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                localStorage.setItem('authToken', data.authToken);
                setIsValid(true);
            } else {
                throw new Error('Failed to refresh token');
            }
        } catch (error) {
            localStorage.removeItem('authToken');
            setIsValid(false);
        }
    };

    // Verify token on mount and set up periodic checks
    useEffect(() => {
        checkTokenValidity();

        // Check token validity every minute
        const intervalId = setInterval(checkTokenValidity, 60000);

        return () => clearInterval(intervalId);
    }, []);

    // Show loading state
    if (isLoading) {
        return <div>Loading...</div>; // Or your loading component
    }

    // Redirect if token is invalid
    if (!isValid) {
        return <Navigate to="/login" replace />;
    }

    // Wrap children with token checking capability
    return (
        <TokenContext.Provider value={{ checkTokenValidity, refreshToken }}>
            {children}
        </TokenContext.Provider>
    );
};

// Create context for token operations
export const TokenContext = React.createContext({
    checkTokenValidity: () => { },
    refreshToken: () => { }
});

// Custom hook for using token operations
export const useToken = () => React.useContext(TokenContext);

// Usage in protected components
export const ProtectedComponent = () => {
    const { checkTokenValidity } = useToken();

    const makeAuthenticatedRequest = async () => {
        // Verify token before making request
        await checkTokenValidity();

        // Make your API request here
        const response = await fetch('/api/protected-data', {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        });

        // Handle response...
    };

    return (
        <div>
            <h1>Protected Component</h1>
            <button onClick={makeAuthenticatedRequest}>
                Fetch Protected Data
            </button>
        </div>
    );
};

export default ProtectedRoute;
/*
import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const ProtectedRoute = ({ children }) => {
    // Check if user is authenticated and token is valid
    const isAuthenticated = () => {
        const authToken = localStorage.getItem('authToken');

        if (!authToken) {
            return false;
        }

        try {
            const decodedToken = jwtDecode(authToken);

            // exp is in seconds, so multiply by 1000 to get milliseconds
            const expirationTime = decodedToken.exp * 1000;
            const currentTime = Date.now();

            // Add a buffer time (e.g., 60 seconds) to handle time differences
            const bufferTime = 60 * 1000;

            if (expirationTime < currentTime - bufferTime) {
                // Token is expired, clean up
                localStorage.removeItem('authToken');
                return false;
            }

            return true;
        } catch (error) {
            // If token can't be decoded, consider it invalid
            localStorage.removeItem('authToken');
            return false;
        }
    };

    // If not authenticated, redirect to login
    if (!isAuthenticated()) {
        return <Navigate to="/login" replace />;
    }

    // If authenticated and token valid, render the protected component
    return children;
};

export default ProtectedRoute;
/*
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    // Check if user is authenticated by looking for the auth token
    const isAuthenticated = () => {
        const authToken = localStorage.getItem('authToken');
        return authToken !== null && authToken.length > 0;
    };

    // If not authenticated, redirect to login
    if (!isAuthenticated()) {
        return <Navigate to="/login" replace />;
    }

    // If authenticated, render the protected component
    return children;
};

export default ProtectedRoute;

/*
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ component: Component, requiredRole, ...rest }) => {
    const { isAuthenticated, isAuthorized } = useAuth();

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated && (requiredRole ? isAuthorized(requiredRole) : true) ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};
*/