import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function LoginPage(props) {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [eventos, setEventos] = useState([]);
    const [selectedEvento, setSelectedEvento] = useState('');
    const [installPrompt, setInstallPrompt] = useState(null);
    const [showInstallBtn, setShowInstallBtn] = useState(false); // New state for showing the install button
    const [showError, setShowError] = useState(false); // New state for error message visibility
    const [fadeError, setFadeError] = useState(false);

    useEffect(() => {
        fetch('/eventos/getall')
            .then(response => response.json())
            .then(data => setEventos(data))
            .then(() => console.log(eventos))
            .catch(error => console.error('Error fetching eventos:', error));
            
        // Listen for the beforeinstallprompt event
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setInstallPrompt(e);
            setShowInstallBtn(true); // Show the install button using React state
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(username, password, selectedEvento);
            const response = await axios.post('/auth/login', {
                username: username,
                password: password
            });

            if (response.data && response.data.authToken) {
                console.log('Login successful!');
                // Store the token in local storage or in a cookie
                localStorage.setItem('authToken', response.data.authToken);
                console.log('EventoId:', selectedEvento);
                localStorage.setItem('eventoId', selectedEvento);

                // Navigate to the Home component
                console.log('We are going to ask for navaigation to /.')
                //navigate('/');
                window.location.href = '/';
                console.log('We asked for navaigation to /.')
            } else {
                // Handle wrong credentials scenario
                setShowError(true);
                setFadeError(false); // Reset the fade state
                setTimeout(() => setFadeError(true), 2000); // Start fading out after 1 second
            }
        } catch (error) {
            console.error("Login error:", error);
            setShowError(true);
            setFadeError(false); // Reset the fade state
            setTimeout(() => {
                setFadeError(true);
                setTimeout(() => setShowError(false), 1000); // 3 seconds is the duration of the fade-out transition
            }, 2000);
        }
    };

    const handleInstallClick = () => {
        if (installPrompt) {
            installPrompt.prompt();
            installPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setInstallPrompt(null);
                setShowInstallBtn(false); // Hide the button after user makes a choice
            });
        }
    };

    return (
        <div className="login-container">
            <h2>Autenticação</h2>
            {showError && <div className={`errorMessage ${fadeError ? 'fadeOut' : ''}`}>Ocorreu um erro de autenticação.</div>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Nome de utilizador:</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        title="Por favor preencha o nome de utilizador."
                    />
                </div>
                <div>
                    <label>Palavra-passe:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        title="Por favor preencha a palavra-passe."
                    />
                </div>
                <div>
                    <label>Seleccione o evento:</label>
                    <select
                        value={selectedEvento}
                        onChange={(e) => {
                            setSelectedEvento(e.target.value);
                            localStorage.setItem('eventoId', e.target.value);
                        }}
                        required
                        title="Por favor escolha um evento."
                        x-moz-errormessage="Por favor escolha um evento."
                    >
                        <option value="">--Seleccione um evento--</option>
                        {eventos.map(evento => (
                            <option key={evento.idEvento} value={evento.idEvento}>
                                {evento.designaçãoDoEvento}
                            </option>
                        ))}
                    </select>
                </div>
                <button type="submit">Ok</button>
            </form>

            {showInstallBtn && <button onClick={handleInstallClick}>Instalar aplicação</button>}
        </div>
    );
}

export default LoginPage;
