import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const SubmeterInscricao = () => {
    const [searchParams] = useSearchParams();
    const inscricaoKey = searchParams.get('ik');
    const tipodeconvite = searchParams.get('tc');

    const [formData, setFormData] = useState({
        expositorId: inscricaoKey,
        tipoDeConviteId: tipodeconvite,
        nome: '',
        email: '',
        telefone: ''
    });

    const [expositor, setExpositor] = useState('');
    const [tipoDeConvite, setTipoDeConvite] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [serverError, setServerError] = useState(false);

    useEffect(() => {
        const fetchExpositor = async () => {
            try {
                const response = await fetch(`/inscricoes/get-expositor?ik=${inscricaoKey}&tc=${tipodeconvite}`);
                if (!response.ok) {
                    if (response.status === 404) {
                        setServerError(true);
                        setError('Ocorreu um problema ao processar o seu pedido');
                    } else {
                        throw new Error('Network response was not ok');
                    }
                    return;
                }

                const data = await response.json();
                if (data.expositor) {
                    setExpositor(data.expositor);
                    if (data.tipoDeConvite) 
                        setTipoDeConvite(data.tipoDeConvite);
                    // Pre-fill form if data exists
                    if (data.inscricao) {
                        setFormData({
                            expositorId: inscricaoKey || '',
                            tipoDeConviteId: tipodeconvite || '',
                            nome: data.inscricao.nome || '',
                            email: data.inscricao.email || '',
                            telefone: data.inscricao.telefone || ''
                        });
                    }
                } else {
                    setServerError(true);
                    setError('Ocorreu um problema ao processar o seu pedido');
                }
            } catch (err) {
                setServerError(true);
                setError('Ocorreu um problema ao processar o seu pedido');
            } finally {
                setIsLoading(false);
            }
        };

        if (inscricaoKey) {
            fetchExpositor();
        } else {
            setServerError(true);
            setError('Link inválido');
            setIsLoading(false);
        }
    }, [inscricaoKey]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const validateForm = () => {
        if (!formData.nome.trim()) {
            setError('Por favor, insira o seu nome.');
            return false;
        }
        if (!formData.email.trim() || !formData.email.includes('@')) {
            setError('Por favor, insira um email válido.');
            return false;
        }
        if (!formData.telefone.trim()) {
            setError('Por favor, insira o seu telefone/telemóvel.');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!validateForm()) {
            return;
        }

        try {
            const response = await fetch('/inscricoes/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    inscricaoKey,
                    ...formData
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                setError(errorData.message || 'Ocorreu um erro ao submeter a inscrição.');
                return;
            }

            setSuccess(true);

        } catch (err) {
            setError('Ocorreu um erro ao submeter a inscrição. Por favor, tente novamente.');
        }
    };

    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh'
            }}>
                <p>A carregar...</p>
            </div>
        );
    }

    if (serverError) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh'
            }}>
                <p style={{ color: '#dc2626' }}>{error}</p>
            </div>
        );
    }

    if (success) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                padding: '1rem'
            }}>
                <div style={{
                    maxWidth: '28rem',
                    width: '100%',
                    backgroundColor: 'white',
                    borderRadius: '0.5rem',
                    padding: '1.5rem',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
                }}>
                    <h2 style={{
                        fontSize: '1.5rem',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#16a34a',
                        marginBottom: '1rem'
                    }}>
                        Inscrição Submetida
                    </h2>
                    <p style={{ textAlign: 'center' }}>
                        A sua inscrição foi registada com sucesso.
                    </p>
                </div>
            </div>
        );
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            padding: '1rem'
        }}>
            <div style={{
                maxWidth: '28rem',
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '0.5rem',
                padding: '1.5rem',
                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
            }}><h5 style={{
                textAlign: 'center',
            }}>Inscrição a convite de<br /></h5>
                <h2 style={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    marginBottom: '0.5rem'
                }}>
                    {expositor}
                </h2>
                <h6 style={{
                    fontSize: '1.0rem',
                textAlign: 'center',
                marginBottom: '1.5rem'
                }}>
                    ({tipoDeConvite})                
                </h6>

                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <div>
                        <input
                            type="hidden"
                            name="expositorId"
                            value={formData.expositorId}
                        />
                        <input
                            type="hidden"
                            name="tipoDeConviteId"
                            value={formData.tipoDeConviteId}
                        />
                        <label
                            htmlFor="nome"
                            style={{
                                display: 'block',
                                marginBottom: '0.5rem',
                                fontSize: '0.875rem',
                                fontWeight: '500'
                            }}
                        >
                            Nome
                        </label>
                        <input
                            type="text"
                            id="nome"
                            name="nome"
                            value={formData.nome}
                            onChange={handleInputChange}
                            style={{
                                width: '100%',
                                padding: '0.5rem',
                                border: '1px solid #d1d5db',
                                borderRadius: '0.375rem'
                            }}
                            placeholder="O seu nome"
                        />
                    </div>

                    <div>
                        <label
                            htmlFor="email"
                            style={{
                                display: 'block',
                                marginBottom: '0.5rem',
                                fontSize: '0.875rem',
                                fontWeight: '500'
                            }}
                        >
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            style={{
                                width: '100%',
                                padding: '0.5rem',
                                border: '1px solid #d1d5db',
                                borderRadius: '0.375rem'
                            }}
                            placeholder="o.seu.email@exemplo.com"
                        />
                    </div>

                    <div>
                        <label
                            htmlFor="telefone"
                            style={{
                                display: 'block',
                                marginBottom: '0.5rem',
                                fontSize: '0.875rem',
                                fontWeight: '500'
                            }}
                        >
                            Telefone
                        </label>
                        <input
                            type="tel"
                            id="telefone"
                            name="telefone"
                            value={formData.telefone}
                            onChange={handleInputChange}
                            style={{
                                width: '100%',
                                padding: '0.5rem',
                                border: '1px solid #d1d5db',
                                borderRadius: '0.375rem'
                            }}
                            placeholder="O seu número de telefone/telemóvel"
                        />
                    </div>

                    {error && (
                        <p style={{
                            color: '#dc2626',
                            fontSize: '0.875rem',
                            marginTop: '0.5rem'
                        }}>
                            {error}
                        </p>
                    )}

                    <button
                        type="submit"
                        style={{
                            width: '100%',
                            backgroundColor: '#2563eb',
                            color: 'white',
                            padding: '0.5rem 1rem',
                            borderRadius: '0.375rem',
                            border: 'none',
                            cursor: 'pointer',
                            marginTop: '1rem'
                        }}
                    >
                        Submeter Inscrição
                    </button>
                </form>
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '10px'
                    }}
                >
                    <a href="https://www.hub.pt" target="_blank">Powered by HUB</a></div>
            </div>
        </div>
    );
};

export default SubmeterInscricao;