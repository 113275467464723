import React, { Component, useState, useEffect } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import './NavMenu.css';

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            brandText: 'Loading...'
        };
    }

    componentDidMount() {
        fetch('/acontecimento')
            .then(response => response.json())
            .then(data => {
                this.setState({ brandText: data.designaçãoDoAcontecimentoReduzida });
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.setState({ brandText: 'Error loading brand text' });
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({ collapsed: true });
        }
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    handleLogout() {
        localStorage.setItem('authToken', '');
    }

    render() {
        const isAuthenticated = localStorage.getItem('authToken') !== null &&
            localStorage.getItem('authToken').length > 0;
        const isSubmeterInscricao = this.props.location.pathname.startsWith('/submeter-inscricao');

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
                    <NavbarBrand tag={Link} to="/">
                        <div id="BrandContainer">
                            <div id="BrandLogoContainer">
                                <img
                                    alt=""
                                    src="/favicon.png"
                                    style={{
                                        height: 40,
                                        width: 40
                                    }}
                                />
                            </div>
                            <div id="BrandTextContainer">
                                {this.state.brandText}
                            </div>
                        </div>
                    </NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            {isAuthenticated && !isSubmeterInscricao && (
                                <>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/">Principal</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/escolher-camara">Escolher câmara</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/alterar-evento">Alterar evento</NavLink>
                                    </NavItem>
                                </>
                            )}
                            {/* Only show login/logout when not on submeter-inscricao page */}
                            {!isSubmeterInscricao && (
                                <NavItem>
                                    <NavLink
                                        tag={Link}
                                        className="text-dark"
                                        to="/login"
                                        onClick={this.handleLogout}
                                    >
                                        {isAuthenticated ? 'Logout' : 'Login'}
                                    </NavLink>
                                </NavItem>
                            )}
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}

function NavMenuWrapper() {
    const location = useLocation();
    return <NavMenu location={location} />;
}

export default NavMenuWrapper;
//import React, { Component, useState, useEffect } from 'react';
//import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
//import { Link, useLocation } from 'react-router-dom';
//import './NavMenu.css';

//class NavMenu extends Component {
//    static displayName = NavMenu.name;

//    constructor(props) {
//        super(props);

//        this.toggleNavbar = this.toggleNavbar.bind(this);
//        this.state = {
//            collapsed: true,
//            brandText: 'Loading...' // Initial state for brand text
//        };
//    }

//    componentDidMount() {
//        // Fetch data from AcontecimentoController
//        fetch('/acontecimento')
//            .then(response => response.json())
//            .then(data => {
//                this.setState({ brandText: data.designaçãoDoAcontecimentoReduzida }); // Replace 'someProperty' with the actual property name from the data
//            })
//            .catch(error => {
//                console.error('Error fetching data:', error);
//                this.setState({ brandText: 'Error loading brand text' });
//            });
//    }

//    componentDidUpdate(prevProps) {
//        // Check if the location has changed
//        if (this.props.location !== prevProps.location) {
//            this.setState({ collapsed: true });
//        }
//    }

//    toggleNavbar() {
//        this.setState({
//            collapsed: !this.state.collapsed
//        });
//    }

//    handleLogout() {
//        localStorage.setItem('authToken', '');
//        // Any other logout-related tasks can be added here
//    }

//    render() {
//        const isAuthenticated = localStorage.getItem('authToken') !== null &&
//            localStorage.getItem('authToken').length > 0;

//        return (
//            <header>
//                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
//                    <NavbarBrand tag={Link} to="/"><div id="BrandContainer"><div id="BrandLogoContainer"><img
//                        alt=""
//                        src="/favicon.png"
//                        style={{
//                            height: 40,
//                            width: 40
//                        }}
//                    /></div><div id="BrandTextContainer">{this.state.brandText}</div></div></NavbarBrand>
//                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
//                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
//                        <ul className="navbar-nav flex-grow">
//                            {isAuthenticated && (
//                                <>
//                                    <NavItem>
//                                        <NavLink tag={Link} className="text-dark" to="/">Principal</NavLink>
//                                    </NavItem>
//                                    <NavItem>
//                                        <NavLink tag={Link} className="text-dark" to="/escolher-camara">Escolher câmara</NavLink>
//                                    </NavItem>
//                                    <NavItem>
//                                        <NavLink tag={Link} className="text-dark" to="/alterar-evento">Alterar evento</NavLink>
//                                    </NavItem>
//                                </>
//                            )}
//                            <NavItem>
//                                <NavLink
//                                    tag={Link}
//                                    className="text-dark"
//                                    to="/login"
//                                    onClick={this.handleLogout}
//                                >
//                                    {isAuthenticated ? 'Logout' : 'Login'}
//                                </NavLink>
//                            </NavItem>
//                        </ul>
//                    </Collapse>
//                </Navbar>
//            </header>
//        );
//    }

//    //render() {
//    //    return (
//    //        <header>
//    //            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
//    //                <NavbarBrand tag={Link} to="/"><img
//    //                    alt=""
//    //                    src="/favicon.png"
//    //                    style={{
//    //                        height: 40,
//    //                        width: 40
//    //                    }}
//    //                />{this.state.brandText}</NavbarBrand>
//    //                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
//    //                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
//    //                    <ul className="navbar-nav flex-grow">
//    //                        <NavItem>
//    //                            <NavLink tag={Link} className="text-dark" to="/">Principal</NavLink>
//    //                        </NavItem>
//    //                        <NavItem>
//    //                            <NavLink tag={Link} className="text-dark" to="/escolher-camara">Escolher câmara</NavLink>
//    //                        </NavItem>
//    //                        <NavItem>
//    //                            <NavLink tag={Link} className="text-dark" to="/alterar-evento">Alterar evento</NavLink>
//    //                        </NavItem>
//    //                        <NavItem>
//    //                            <NavLink tag={Link} className="text-dark" to="/login" onClick={this.handleLogout}>Logout</NavLink>
//    //                        </NavItem>
//    //                    </ul>
//    //                </Collapse>
//    //            </Navbar>
//    //        </header>
//    //    );
//    //}
//}
//function NavMenuWrapper() {
//    const location = useLocation();

//    return <NavMenu location={location} />;
//}

//export default NavMenuWrapper;
