import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const SendAll = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/sendall/get');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                setIsLoading(false);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError('Ocorreu um erro ao processar o pedido.');
                setIsLoading(false);
            }
        };

        fetchData();
    }, []); // Empty dependency array means this runs once on component mount

    if (isLoading) {
        return <div>A carregar...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return <div>E-mails enviados com sucesso.</div>;
};

export default SendAll;