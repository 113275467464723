import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// Create a custom event for SW updates
const swUpdateEvent = new Event('swUpdate');

root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>
);

// Register service worker with update handling
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    // Dispatch custom event that App can listen for
    window.dispatchEvent(swUpdateEvent);

    // Optional: Force immediate update
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          // Force reload once the new service worker is activated
          window.location.reload();
        }
      });

      waitingServiceWorker.postMessage('SKIP_WAITING');
    }
  },
  onSuccess: (registration) => {
    console.log('Service Worker registration successful');
  }
});

// Add update check on page visibility change
document.addEventListener('visibilitychange', () => {
  if (document.visibilityState === 'visible') {
    navigator.serviceWorker.getRegistration().then(registration => {
      if (registration) {
        registration.update();
      }
    });
  }
});

reportWebVitals();
/*
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>);

serviceWorkerRegistration.register();

reportWebVitals();
*/