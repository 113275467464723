import React, { Component } from 'react';
import { LoadingOverlay } from './LoadingOverlay';
import { Container } from 'reactstrap';
import NavMenuWrapper from './NavMenu';

export class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUpdating: false
        };
    }

    componentDidMount() {
        window.addEventListener('swUpdate', this.handleUpdate);
    }

    componentWillUnmount() {
        window.removeEventListener('swUpdate', this.handleUpdate);
    }

    handleUpdate = () => {
        this.setState({ isUpdating: true });
        // Force update after a short delay
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    render() {
        return (
            <div>
                {this.state.isUpdating && <LoadingOverlay />}
                <div>
                    <NavMenuWrapper />
                    <Container tag="main">
                        {this.props.children}
                    </Container>
                </div>
            </div>
        );
    }
}
/*
import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenuWrapper from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <NavMenuWrapper />
                <Container tag="main">
                    {this.props.children}
                </Container>
            </div>
        );
    }
}
*/