import AlterarEvento from "./components/AlterarEvento";
import EscolherCamara from "./components/EscolherCamara";
import Home from "./components/Home";
import LoginPage from './components/LoginPage';
import ProtectedRoute from './components/ProtectedRoute';
import SubmeterInscricao from "./components/SubmeterInscricao";
import SendAll from "./components/SendAll";

const AppRoutes = [
    {
        path: '/',
        element: (
            <ProtectedRoute>
                <Home />
            </ProtectedRoute>
        )
    },
    {
        path: '/login',
        element: <LoginPage />
    },
    {
        path: '/alterar-evento',
        element: (
            <ProtectedRoute>
                <AlterarEvento />
            </ProtectedRoute>
        )
    },
    {
        path: '/escolher-camara',
        element: (
            <ProtectedRoute>
                <EscolherCamara />
            </ProtectedRoute>
        )
    },
    {
        path: '/submeter-inscricao',
        element: <SubmeterInscricao />
    },
    {
        path: 'sendall',
        element: <SendAll />
    }
];

export default AppRoutes;

//import AlterarEvento from "./components/AlterarEvento";
//import EscolherCamara from "./components/EscolherCamara";
//import Home from "./components/Home";
//import LoginPage from './components/LoginPage';
//
//function ProtectedRoute() {
//    console.log('This is the ProtectedRoute.');
//    if (isAuthenticated()) {
//        return <Home />;
//    } else {
//        return <LoginPage />;
//    }
//}
//
//function isAuthenticated() {
//    // Example authentication check
//    console.log('authToken is ' + localStorage.getItem('authToken'));
//    console.log(localStorage.getItem('authToken') !== null && localStorage.getItem('authToken').length > 0);
//    return localStorage.getItem('authToken') !== null && localStorage.getItem('authToken').length > 0;
//}
//
//const AppRoutes = [
//    {
//        path: '/',
//        element: <ProtectedRoute />
//    },
//    {
//        path: '/login',
//        element: <LoginPage />
//    },
//    {
//        path: '/alterar-evento',
//        element: <AlterarEvento />
//    },
//    {
//        path: '/escolher-camara',
//        element: <EscolherCamara />
//    }
//];
//
//export default AppRoutes;