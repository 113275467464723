import { Component } from 'react';

export class VersionChecker extends Component {
    state = {
        checkInterval: null
    };

    componentDidMount() {
        // Check version every 5 minutes
        const checkInterval = setInterval(this.checkVersion, 5 * 60 * 1000);
        this.setState({ checkInterval });
    }

    componentWillUnmount() {
        if (this.state.checkInterval) {
            clearInterval(this.state.checkInterval);
        }
    }

    checkVersion = async () => {
        try {
            const response = await fetch('/version.json', {
                cache: 'no-store'
            });
            const { version } = await response.json();

            const currentVersion = process.env.REACT_APP_VERSION;
            if (version !== currentVersion) {
                window.dispatchEvent(new Event('swUpdate'));
            }
        } catch (error) {
            console.error('Version check failed:', error);
        }
    };

    render() {
        return null; // This component doesn't render anything
    }
}