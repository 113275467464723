import React, { useRef, useCallback, useEffect, useReducer, useState } from 'react';
import jsQR from 'jsqr';

const qrDataReducer = (state, action) => {
    switch (action.type) {
        case 'SET_QR_DATA':
            return { ...state, ...action.payload };
        case 'RESET_QR_DATA':
            return null;
        default:
            return state;
    }
};

function Home() {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const lastQRCodeRef = useRef(null);
    const scanningRef = useRef(false);

    const [eventoActual, setEventoActual] = useState({});
    const [selectedDeviceId, setSelectedDeviceId] = useState('');
    const [qrData, dispatch] = useReducer(qrDataReducer, null);
    const [hasPermission, setHasPermission] = useState(false);
    const [permissionError, setPermissionError] = useState(null);

    const startCamera = async () => {
        try {
            const constraints = {
                video: {
                    deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
                    facingMode: selectedDeviceId ? undefined : { ideal: 'environment' },
                    width: { ideal: 1280 },
                    height: { ideal: 720 }
                }
            };

            const stream = await navigator.mediaDevices.getUserMedia(constraints);

            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                // Do we have a way to know if it's front camera? If it's front camera we should mirror the video
                // videoRef.current.style.transform = 'scaleX(-1)'; // Mirror front camera
                await videoRef.current.play();
                scanningRef.current = true;
                requestAnimationFrame(scan);
            }
        } catch (err) {
            console.error("Error accessing camera:", err);
            setPermissionError(err.message);
        }
    };

    // Force viewport meta tag for mobile devices
    useEffect(() => {
        const meta = document.createElement('meta');
        meta.name = "viewport";
        meta.content = "width=device-width, initial-scale=1, maximum-scale=1";
        document.head.appendChild(meta);

        return () => {
            document.head.removeChild(meta);
        };
    }, []);

    useEffect(() => {
        async function setupCamera() {
            try {
                // First request camera permission with preferred facing mode
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        facingMode: { ideal: 'environment' } // Prefer back camera
                    }
                });

                // Stop the initial stream
                stream.getTracks().forEach(track => track.stop());
                setHasPermission(true);

                // Now enumerate devices
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(device => device.kind === 'videoinput');

                // Try to find the back camera
                const backCamera = videoDevices.find(device =>
                    device.label.toLowerCase().includes('back') ||
                    device.label.toLowerCase().includes('rear') ||
                    device.label.toLowerCase().includes('environment')
                );

                let desiredDeviceId = '';
                try {
                    desiredDeviceId = localStorage.getItem('desiredDeviceId');
                    // If no stored ID or stored ID not found in current devices, prefer back camera
                    if (!desiredDeviceId || !videoDevices.find(d => d.deviceId === desiredDeviceId)) {
                        desiredDeviceId = backCamera ? backCamera.deviceId : videoDevices[0]?.deviceId;
                    }
                } catch {
                    desiredDeviceId = backCamera ? backCamera.deviceId : videoDevices[0]?.deviceId;
                }

                localStorage.setItem('desiredDeviceId', desiredDeviceId);
                setSelectedDeviceId(desiredDeviceId);
            } catch (error) {
                console.error('Error setting up camera:', error);
                setPermissionError(error.message);
                setHasPermission(false);
            }
        }

        setupCamera();

        fetch('/eventos/get?idEvento=' + localStorage.getItem('eventoId'))
            .then(response => response.json())
            .then(data => {
                setEventoActual(data);
            })
            .catch(error => console.error('Error fetching eventos:', error));

        return () => {
            if (videoRef.current?.srcObject) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            }
            scanningRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (!hasPermission || !selectedDeviceId) return;

        startCamera();

        return () => {
            if (videoRef.current?.srcObject) {
                videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            }
            scanningRef.current = false;
        };
    }, [selectedDeviceId, hasPermission]);

    const scan = useCallback(async () => {
        if (!scanningRef.current) return;

        if (videoRef.current?.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height);

            if (code && code.binaryData) {
                const binaryData = code.binaryData;
                const decodedString = String.fromCharCode(...binaryData);
                if (lastQRCodeRef.current !== decodedString) {
                    if ("vibrate" in navigator) {
                        navigator.vibrate(50);
                    }
                    await parseQRCode(decodedString);
                    lastQRCodeRef.current = decodedString;
                }
            }
        }

        if (scanningRef.current) {
            requestAnimationFrame(scan);
        }
    }, []);

    const ParsedQRCode = ({ qrData }) => {
        if (!qrData) {
            return null; // or return a loading indicator
        }
        const { mensagemDeErro, estaNoEvento, podeEntrar, numeroDeEntradas } = qrData;
        return (
            <div style={{
                backgroundColor: '#FFFFFF',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '82vw',
                width: '100%'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    flex: 1
                }}>
                    {mensagemDeErro.length == 0
                        ? (estaNoEvento
                            ? (!podeEntrar
                                ? <p style={{ backgroundColor: '#dc3545', color: '#fff', fontSize: '14pt', textAlign: 'center' }}>&nbsp; &nbsp; &nbsp;Este QR Code já esgotou as entradas neste evento.&nbsp; &nbsp; &nbsp;</p>
                                : <p style={{ backgroundColor: '#198754', color: '#fff', fontSize: '14pt', textAlign: 'center' }}>&nbsp; &nbsp; &nbsp;QR Code válido, número de entradas restantes: {numeroDeEntradas}.&nbsp;&nbsp;&nbsp;</p>
                            ) : <p style={{ backgroundColor: '#cccccc', color: '#000', fontSize: '14pt', textAlign: 'center' }}>&nbsp; &nbsp; &nbsp;Este QR Code não está na lista deste evento.&nbsp;&nbsp;&nbsp;</p>
                        ) : <p style={{ backgroundColor: '#dc3545', color: '#fff', fontSize: '14pt', textAlign: 'center' }}>&nbsp; &nbsp; &nbsp;{mensagemDeErro} &nbsp;&nbsp;&nbsp;</p>}
                
                </div>
                <button onClick={async () => {
                    lastQRCodeRef.current = null; // Reset the last QR code
                    dispatch({
                        type: 'RESET_QR_DATA'
                    });
                    await startCamera(); // Restart the camera
                }}>Continuar</button>
            </div>
        );
    };


    const parseQRCode = useCallback(async (qrText) => {
        while (qrText.includes('\n\n')) {
            qrText = qrText.replace(/\n\n/g, '\n');
        }

        const parts = qrText.split('\n');
        const idLastConvidado = parts[0];
        const nomeDoConvidado = parts[1];
        var mensagemDeErro = "";

        // Verify that idLastConvidado is a valid UUID
        if (!idLastConvidado.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i)) {
            mensagemDeErro = "Ocorreu um erro ao ler o QR Code.";
        } else {
            var estaNoEvento = false;
            var podeEntrar = false;
            var numeroDeEntradas = 0;
            try {
                const response = await fetch(`/convidadosnoseventos?idConvidado=${idLastConvidado}&idEvento=${localStorage.getItem('eventoId')}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok' + response.statusText);
                }
                const additionalInfo = await response.json();

                estaNoEvento = additionalInfo.estaNoEvento;
                podeEntrar = additionalInfo.podeEntrar;
                numeroDeEntradas = additionalInfo.numeroDeEntradas;

            } catch (error) {
                console.error('Fetch error: ', error);
                mensagemDeErro = "Ocorreu um erro ao tentar aceder ao servidor."
            }
        }

        dispatch({
            type: 'SET_QR_DATA',
            payload: { mensagemDeErro, estaNoEvento, podeEntrar, numeroDeEntradas }
        });
    }, []);


    return (
        <div>
            <h2 style={{ textAlign: 'right' }}>Evento actual: {eventoActual.designaçãoDoEvento}</h2>

            {permissionError && (
                <div style={{
                    backgroundColor: '#ffebee',
                    color: '#c62828',
                    padding: '10px',
                    margin: '10px 0',
                    borderRadius: '4px'
                }}>
                    Erro de acesso à câmara: {permissionError}
                </div>
            )}

            {qrData ? (
                <div className="message-container" style={{ backgroundColor: qrData.color ? qrData.color : '#444' }}>
                    <ParsedQRCode qrData={qrData} />
                </div>
            ) : (
                <>
                    {hasPermission ? (
                        <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                margin: '20px auto',
                                padding: '21px',
                                border: '1px solid #ccc',
                                borderRadius: '6px',
                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                                paddingBottom: '0px',
                                width: '100%',
                                maxWidth: '100vw',
                                aspectRatio: '1', // This makes it square
                                position: 'relative',
                                //backgroundColor: '#000' // Optional: helps with visibility
                        }}>
                            <div className="video-inner-container" style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                                overflow: 'hidden' // Ensures video doesn't spill out
                            }}>
                                <video
                                    ref={videoRef}
                                    autoPlay
                                    playsInline
                                    muted
                                    style={{
                                        //width: '100%',
                                        //height: '100%',
                                        objectFit: 'cover',
                                        position: 'absolute',
                                        //top: '50%',
                                        //left: '50%',
                                        //transform: 'translate(-50%, -50%)', // Centers the video
                                    }}
                                />
                            </div>
                            <div style={{
                                position: 'absolute',
                                bottom: '20px',
                                left: 0,
                                right: 0,
                                textAlign: 'center',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                color: 'white',
                                padding: '10px'
                            }}>
                                <span>Aponte a câmara para o QR Code e aguarde</span>
                            </div>
                        </div>
                    ) : (
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                            {!permissionError && <span>A aguardar permissão para aceder à câmara...</span>}
                        </div>
                    )}

                </>
            )}

            <canvas ref={canvasRef} style={{ display: 'none' }} />
        </div>
    );
}

export default Home;