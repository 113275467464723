import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function EscolherCamara() {
    const navigate = useNavigate();
    const [videoDevices, setVideoDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState('');
    const [permissionError, setPermissionError] = useState(null);

    useEffect(() => {
        async function getDevices() {
            try {
                // First request camera permission with environment camera preference
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        facingMode: { ideal: 'environment' } // Prefer back camera
                    }
                });

                // Stop the initial stream
                stream.getTracks().forEach(track => track.stop());

                // Now enumerate devices
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(device => device.kind === 'videoinput');

                // Try to identify back camera
                const backCamera = videoDevices.find(device =>
                    device.label.toLowerCase().includes('back') ||
                    device.label.toLowerCase().includes('rear') ||
                    device.label.toLowerCase().includes('environment')
                );

                // Sort cameras to prioritize back cameras
                const sortedDevices = videoDevices.sort((a, b) => {
                    const aIsBack = a.label.toLowerCase().includes('back') ||
                        a.label.toLowerCase().includes('rear') ||
                        a.label.toLowerCase().includes('environment');
                    const bIsBack = b.label.toLowerCase().includes('back') ||
                        b.label.toLowerCase().includes('rear') ||
                        b.label.toLowerCase().includes('environment');
                    return bIsBack - aIsBack;
                });

                setVideoDevices(sortedDevices);

                // Set the default camera
                let desiredDeviceId;
                try {
                    desiredDeviceId = localStorage.getItem('desiredDeviceId');
                    // If no stored ID or stored ID not found in current devices, prefer back camera
                    if (!desiredDeviceId || !videoDevices.find(d => d.deviceId === desiredDeviceId)) {
                        desiredDeviceId = backCamera ? backCamera.deviceId : videoDevices[0]?.deviceId;
                    }
                } catch {
                    desiredDeviceId = backCamera ? backCamera.deviceId : videoDevices[0]?.deviceId;
                }

                if (desiredDeviceId) {
                    localStorage.setItem('desiredDeviceId', desiredDeviceId);
                    setSelectedDeviceId(desiredDeviceId);
                }

            } catch (error) {
                console.error('Error accessing camera devices:', error);
                setPermissionError(error.message);
            }
        }

        getDevices();
    }, []);

    const handleCameraChange = (event) => {
        localStorage.setItem('desiredDeviceId', event.target.value);
        setSelectedDeviceId(event.target.value);
        navigate('/');
    };

    return (
        <div style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        }}>
            {permissionError ? (
                <div style={{
                    backgroundColor: '#ffebee',
                    color: '#c62828',
                    padding: '10px',
                    borderRadius: '4px',
                    marginBottom: '10px'
                }}>
                    Erro de acesso à câmara: {permissionError}
                </div>
            ) : null}

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px'
            }}>
                <label htmlFor="camera-select" style={{ fontWeight: 'bold' }}>
                    Seleccione a câmara:
                </label>
                <select
                    id="camera-select"
                    onChange={handleCameraChange}
                    value={selectedDeviceId}
                    style={{
                        padding: '8px',
                        fontSize: '16px', // Better for mobile touch
                        borderRadius: '4px',
                        border: '1px solid #ccc',
                        backgroundColor: '#fff'
                    }}
                >
                    {videoDevices.length === 0 && (
                        <option value="">Nenhuma câmara encontrada</option>
                    )}
                    {videoDevices.map(device => (
                        <option key={device.deviceId} value={device.deviceId}>
                            {device.label || (
                                device.deviceId === selectedDeviceId
                                    ? 'Câmara atual'
                                    : `Câmara ${device.deviceId.slice(0, 4)}...`
                            )}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default EscolherCamara;

/*
import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function EscolherCamara() {
    const navigate = useNavigate();
    const [videoDevices, setVideoDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState('');

    useEffect(() => {
        // Enumerate video devices
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                setVideoDevices(videoDevices);
                // Set the default camera to the first one or to the desired one if available
                var desiredDeviceId = '';
                try {
                    desiredDeviceId = localStorage.getItem('desiredDeviceId');
                } catch {
                    desiredDeviceId = videoDevices[0].deviceId;
                    localStorage.setItem('desiredDeviceId', desiredDeviceId);
                }
                const defaultDevice = videoDevices.find(device => device.deviceId === desiredDeviceId) || videoDevices[0];
                setSelectedDeviceId(defaultDevice ? defaultDevice.deviceId : null);
            });
    }, []);

    const handleCameraChange = (event) => {
        localStorage.setItem('desiredDeviceId', event.target.value);
        setSelectedDeviceId(event.target.value);
        navigate('/');
    };

    return (
        <div>
            <label>Seleccione a câmara:</label>
            <select onChange={handleCameraChange} value={selectedDeviceId}>
                {videoDevices.map(device => (
                    <option key={device.deviceId} value={device.deviceId}>
                        {device.label || `Camera ${device.deviceId}`}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default EscolherCamara;
*/