import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function AlterarEvento() {
    const navigate = useNavigate();
    const [eventos, setEventos] = useState([]);
    const [selectedEvento, setSelectedEvento] = useState('');

    useEffect(() => {
        // Fetch events from the server when the component mounts
        fetch('/eventos/getall')
            .then(response => response.json())
            .then(data => setEventos(data))
            .catch(error => console.error('Error fetching events:', error));
    }, []);

    return (
        <div>
            <label>Seleccione o evento:</label>
            <select
                value={selectedEvento}
                onChange={(e) => { localStorage.setItem('eventoId', e.target.value); navigate('/'); }}
                required
                title="Por favor escolha um evento."
                x-moz-errormessage="Por favor escolha um evento."
            >
                <option value="">--Seleccione um evento--</option>
                {eventos.map(evento => (
                    <option key={evento.idEvento} value={evento.idEvento}>
                        {evento.designaçãoDoEvento}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default AlterarEvento;
